import React from "react";
import Data from "../components/Data";
import Nav from "../components/Nav";

function CreatePage() {
  return (
    <>
      <Nav />
      <Data />
    </>
  );
}

export default CreatePage;
